import { useState } from "react";
import { Menu, X, Github, Linkedin, Mail } from "lucide-react";
import { motion } from "framer-motion";

export default function Component() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  const colors = {
    background: "#15202b", // Dark Blue Gray
    textPrimary: "#ffffff", // White
    textSecondary: "#cfd9de", // Light Gray
    cardBackground: "#192734", // Darker Blue Gray
    border: "#253341", // Dark Gray
    hover: "#1b2836", // Hover Dark Gray
  };

  return (
    <div
      className="min-h-screen"
      style={{
        backgroundColor: colors.background,
        color: colors.textPrimary,
        fontSize: "1.1em",
        letterSpacing: "0.01em",
        lineHeight: "1.6",
      }}
    >
      <header
        className="shadow-md sticky top-0 z-10"
        style={{ backgroundColor: colors.background }}
      >
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <motion.h1
            className="text-2xl font-bold"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            Ahmed Elsayed
          </motion.h1>
          <nav className="hidden md:flex space-x-4">
            {["about", "experience", "projects", "skills", "contact"].map(
              (item, index) => (
                <motion.a
                  key={item}
                  href={`#${item}`}
                  className="transition-colors duration-200"
                  style={{ color: colors.textSecondary }}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </motion.a>
              ),
            )}
          </nav>
          <motion.button
            onClick={toggleMenu}
            className="md:hidden"
            style={{ color: colors.textSecondary }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            {isMenuOpen ? <X /> : <Menu />}
          </motion.button>
        </div>
        {isMenuOpen && (
          <motion.div
            className="md:hidden"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {["about", "experience", "projects", "skills", "contact"].map(
              (item) => (
                <a
                  key={item}
                  href={`#${item}`}
                  className="block py-2 px-4 text-sm transition-colors duration-200"
                  style={{
                    color: colors.textSecondary,
                    backgroundColor: colors.hover,
                  }}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </a>
              ),
            )}
          </motion.div>
        )}
      </header>

      <main className="container mx-auto px-4 py-8">
        <motion.section id="about" className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-4">About Me</h2>
          <div
            className="shadow-lg rounded-lg p-6 border"
            style={{
              backgroundColor: colors.cardBackground,
              borderColor: colors.border,
            }}
          >
            <motion.img
              src="/ahmed-elsayed.jpeg?height=200&width=200"
              alt="Ahmed Elsayed"
              className="w-48 h-48 rounded-full mx-auto mb-4 border-4"
              style={{ borderColor: colors.border }}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            />
            <p className="mb-4" style={{ color: colors.textSecondary }}>
              Hello! I'm Ahmed Elsayed, a Senior Front-End Engineer based in
              Rotterdam, Netherlands. With over six years of experience, I
              specialize in building high-performance mobile and web
              applications using JavaScript, React Native, and other modern
              technologies.
            </p>
            <p style={{ color: colors.textSecondary }}>
              I'm passionate about creating user-friendly interfaces and solving
              complex problems. When I'm not coding, you can find me exploring
              new technologies or enjoying the beautiful city of Rotterdam.
            </p>
          </div>
        </motion.section>

        <motion.section id="experience" className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-4">Experience</h2>
          <div className="space-y-6">
            {[
              {
                title: "Team Lead - Mendix",
                date: "July 2024 - Current",
                responsibilities: [
                  "Led a team of front-end engineers in developing reusable cross-platform widgets and JavaScript actions for the Mendix low-code platform.",
                  "Managed and maintained native mobile experiences for Mendix developers.",
                  "Implemented a reusable styling system, streamlining the design and development process.",
                  "Wrote technical documentation to support knowledge transfer.",
                  "Promoted agile methodologies and mentored team members.",
                  "Optimized native mobile app startup and data actions by 30-70%.",
                ],
              },
              {
                title: "Senior Front-End Engineer - Mendix",
                date: "December 2021 - Current",
                responsibilities: [
                  "Built reusable widgets and JavaScript actions for native mobile apps using JavaScript, React Native, and C#.",
                  "Enhanced native mobile experience and created a reusable styling system.",
                  "Provided technical documentation and thrived in an agile work environment.",
                  "Achieved zero support tickets through robust solutions and proactive issue handling.",
                ],
              },
              {
                title: "Senior Front-End Engineer - Al-Rajhi Bank",
                date: "March 2020 - September 2021",
                responsibilities: [
                  "Developed secure mobile banking apps for iOS and Android using React Native and Redux.",
                  "Worked on the web portal using ReactJS and integrated live backend systems.",
                  "Ensured code quality with automated tests and agile collaboration.",
                ],
              },
              {
                title:
                  "React Native Developer - Vodafone International Services",
                date: "June 2019 - March 2020",
                responsibilities: [
                  "Developed high-performing mobile applications using React Native and Redux, delivering exceptional user experiences.",
                  "Designed a flexible front-end architecture, ensuring scalability and maintainability.",
                  "Created reusable components, improving development efficiency.",
                  "Maintained code quality with testing and automation, minimizing defects.",
                  "Built UI/UX prototypes, collaborating with stakeholders to meet application goals.",
                  "Conducted User Acceptance Testing, gathering feedback for improvements.",
                  "Worked closely with product owners to align application capabilities with business needs.",
                ],
              },
              {
                title: "Intern Full Stack Engineer - Link Development",
                date: "April 2019 - June 2019",
                responsibilities: [
                  "Developed front-end solutions using Angular and back-end solutions with ASP.NET Web API.",
                  "Ensured responsiveness and functionality of applications.",
                ],
              },
              {
                title: "Front-End Developer (Freelance)",
                date: "Dec 2017 - Dec 2021",
                responsibilities: [
                  "Produced fully functional mobile and web applications using React Native and ReactJS.",
                  "Diagnosed and fixed bugs for optimal performance.",
                  "Added new features to existing applications.",
                  "Built smooth, pixel-perfect UIs for web, iOS, and Android.",
                  "Solved product-related issues reported by customers.",
                  "Worked closely with the UI/UX team.",
                ],
              },
            ].map((job, index) => (
              <motion.div
                key={job.title}
                className="shadow-lg rounded-lg p-6 border"
                style={{
                  backgroundColor: colors.cardBackground,
                  borderColor: colors.border,
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-2">{job.title}</h3>
                <p className="mb-2" style={{ color: colors.textSecondary }}>
                  {job.date}
                </p>
                <ul
                  className="list-disc list-inside"
                  style={{ color: colors.textSecondary }}
                >
                  {job.responsibilities.map((responsibility, i) => (
                    <li key={i} className="mb-2">
                      {responsibility}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section id="projects" className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-4">Projects</h2>
          <div className="space-y-6">
            {[
              {
                title: "AlRajhi Mobile",
                description:
                  "Developed a secure, high-performing banking app serving millions of customers.",
                details: [
                  "Integrated with complex back-end systems",
                  "Implemented Google APIs integration",
                  "Managed continuous updates on the App Store and Play Store",
                ],
              },
              {
                title: "Aman Al Rajhi App",
                description:
                  "Security Token App for validating and performing critical transactions and operations.",
                details: [
                  "Designed for installation on any smartphone",
                  "Implemented robust security features",
                ],
              },
              {
                title: "My Vodafone Core App",
                description:
                  "Developed standalone, scalable, and reusable components for all Vodafone group local markets.",
                details: [
                  "Supported local market integration",
                  "Provided comprehensive documentation for local markets",
                ],
              },
              {
                title: "Tazawaj",
                description:
                  "Developed high-performing social media web and mobile applications.",
                details: [
                  "Built using ReactJS for web and React Native for Android and iOS",
                  "Integrated with Ruby back-end",
                  "Implemented AWS Cognito, Pusher, and OneSignal integrations",
                ],
              },
              {
                title: "Pink",
                description:
                  "Led an international team in developing a beauty salon system with mobile applications for customers and salon owners.",
                details: [
                  "Collaborated closely with various company departments",
                  "Managed cross-functional team dynamics",
                ],
              },
              {
                title: "DODOTIK",
                description:
                  "Built app structure and UI components for a home automation application.",
                details: [
                  "Developed from scratch",
                  "Managed deployment on App Store and Play Store",
                  "Integrated IoT libraries",
                ],
              },
            ].map((project, index) => (
              <motion.div
                key={project.title}
                className="shadow-lg rounded-lg p-6 border"
                style={{
                  backgroundColor: colors.cardBackground,
                  borderColor: colors.border,
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="mb-2" style={{ color: colors.textSecondary }}>
                  {project.description}
                </p>
                <ul
                  className="list-disc list-inside"
                  style={{ color: colors.textSecondary }}
                >
                  {project.details.map((detail, i) => (
                    <li key={i}>{detail}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section id="skills" className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-4">Skills</h2>
          <div
            className="shadow-lg rounded-lg p-6 border"
            style={{
              backgroundColor: colors.cardBackground,
              borderColor: colors.border,
            }}
          >
            <div className="flex flex-wrap gap-2">
              {[
                "JavaScript (ES6+)",
                "TypeScript",
                "React",
                "React Native",
                "Redux",
                "Next.js",
                "C#",
                ".NET",
                "TailwindCSS",
                "Firebase",
                "Git",
                "Github",
                "GitLab",
                "AWS (Cognito, Lambda)",
                "Auth0",
                "Bitrise",
                "Jest",
                "Detox",
                "Docker",
                "Agile (Scrum, Kanban)",
                "Test-Driven Development (TDD)",
                "CI/CD",
              ].map((skill, index) => (
                <motion.span
                  key={skill}
                  className="px-3 py-1 rounded-full text-sm"
                  style={{
                    backgroundColor: colors.hover,
                    color: colors.textPrimary,
                  }}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                >
                  {skill}
                </motion.span>
              ))}
            </div>
          </div>
        </motion.section>
        <motion.section id="contact" className="mb-16" {...fadeInUp}>
          <h2 className="text-3xl font-bold mb-4">Contact</h2>
          <div
            className="shadow-lg rounded-lg p-6 border"
            style={{
              backgroundColor: colors.cardBackground,
              borderColor: colors.border,
            }}
          >
            <p className="mb-4" style={{ color: colors.textSecondary }}>
              Feel free to reach out to me via email or connect with me on
              LinkedIn and GitHub.
            </p>
            <div className="flex space-x-4">
              {[
                {
                  href: "mailto:ahmed.elsayed@example.com",
                  icon: Mail,
                  label: "Email",
                },
                {
                  href: "https://github.com/ahmedelsayed",
                  icon: Github,
                  label: "Github",
                },
                {
                  href: "https://linkedin.com/in/ahmedelsayed",
                  icon: Linkedin,
                  label: "LinkedIn",
                },
              ].map((contact, index) => (
                <motion.a
                  key={contact.label}
                  href={contact.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-black transition-colors duration-200"
                  style={{ color: colors.textSecondary }}
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <contact.icon className="w-6 h-6" />
                </motion.a>
              ))}
            </div>
          </div>
        </motion.section>
      </main>

      <footer className="text-gray-300 py-4">
        <motion.div
          className="container mx-auto px-4 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p>
            &copy; {new Date().getFullYear()} Ahmed Elsayed. All rights
            reserved.
          </p>
        </motion.div>
      </footer>
    </div>
  );
}
